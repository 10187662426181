.template-card {
    width: 15rem;
    height: 9rem;
    background-color: #EFEBFA;
    position: relative;
    // position: absolute;
    margin: 10px 10px;
    display: inline-block;
    text-align: center;
    border-radius: 3px;

    .title {
        font-size: 16px;
        margin-top: 13px;
    }

    .body {
        color: #87868B;
        font-size: 14px;
        position: absolute;
        padding:0 5px;
        top: 50px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 超出几行省略 */
        overflow: hidden;
        width:100%;
        text-align: center;
        white-space: normal;
        word-wrap: break-word;
      
    }

    button {
        border: 1px solid #87868B;
        font-size: 14px;
        background-color: white;
        padding: 3px 15px;
        font-size: 12px;
        position: absolute;
        bottom: 10px;
        left: 35%;
    }
}