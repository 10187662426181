.addCrowd-container{
    background: white;
    .page-footer {
        width: 100%;
        text-align: center;
        height: 50px;
        line-height: 10px;
        margin: 20px 0;

        button {
            margin-right: 50px;
        }
    }
}