.add-item-modal {
    .modal-body {
        margin: 0 !important;
        padding: 0 !important;

        .row-box {
            width: 100%;
            margin: auto;
        }

        .col-lg-4,
        .col-lg-8 {
            min-height: 700px;
        }


        .bxs-info-circle,
        .bxs-folder-open {
            color: #8558FA;
            width: 22px;
            font-size: 18px;
            position: relative;
            top: 2px;
        }

        .col-lg-4 {
            border-right: 1px solid rgba(204, 204, 204, 0.486);

            .search-box {
                margin: 20px 0;

                .search-input {
                    width: 280px;
                }

                .search-btn {
                    position: absolute;
                    top: 0px;
                    right: 0;
                    height: 37px;
                    border: 1px solid rgba(204, 204, 204, 0.486);
                }
            }

            .div-tips {
                margin: 10px 5px;
                line-height: 40px;

            }

            .div-checkbox {
                padding: 0 0 0 20px;

                .dripicons-question {
                    position: relative;
                    top: 2px;
                    left: 5px;
                    color: #BDBDBD;
                }
            }
        }

        .col-lg-8 {
            .card {
                margin: 20px;

                .card-header {
                    background-color: #F7F8FA;
                }

                .card-body {
                    .del {
                        color: #8558FA;
                    }

                    .no-data {
                        color: #ccc;
                        text-align: center;
                    }
                }
            }
        }
    }

    .modal-footer {
        display: inline-block !important;
        text-align: center;

        .btn {
            width: 90px
        }

        .btn-save {
            background-color: #8558FA;
        }
    }
}