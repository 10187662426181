.target-population-Modal{
    .modal-body {
        .modal-leftBar,
        .modal-rightBar {
            background-color: #F7F8FA;
        }
    
        .modal-leftBar {
            width: 260px;
            float: left;
            padding: 15px;
            border-radius: 3px;
            margin-top: 15px;
            min-height: 700px;
    
            .tagTitle {
                font-size: 16px;
                border-left: 2px solid #8558FA;
                margin: 20px 0;
                padding-left: 5px;
                height: 16px;
                line-height: 16px;
            }
    
            .tagBar {
                .tagItem {
                    width: 110px;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    background-color: white;
                    display: inline-block;
                    font-size: 13px;
                    margin: 5px 0;
                    border: 1px solid rgba(204, 204, 204, 0.486);
                }
    
                .tagItem:nth-child(odd) {
                    margin-right: 10px;
                }
                .activeTagItem{
                    border: 1px solid #8558fa;
                    color: #8558fa;
                }
            }
        }
    
        .modal-rightBar {
            min-height: 700px;
            margin: 15px -5px 0 10px;
            border-radius: 3px;
            height: 700px;
            overflow-y: auto;
            overflow-x: hidden;
    
            .row-head-box {
                padding: 25px 15px 0 15px;
            }
    
            .modal-contentBar {
                .row-box {
                    background: white;
                    padding: 20px;
                    margin: 30px 30px;
                    border-radius: 3px;
    
                    .form-select {
                        width: 120px;
                        height: 30px;
                        line-height: 13px;
                        margin: 15px 0 0 0;
                    }
                }
            }
        }
    }
    
    .modal-footer {
        display: inline-block !important;
        text-align: center;
        .btn{
            width:90px
        }
        .btn-save{
            background-color: #8558FA;
        }
    }
}