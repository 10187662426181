.modal-body{
    .div-nav-box{
        margin-top:20px;
    }
}
.modal-footer {
    display: inline-block !important;
    text-align: center;

    .btn {
        width: 90px
    }

    .btn-save {
        background-color: #8558FA;
    }
}