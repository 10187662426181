.main-content {
    background-color: #F2F3F5;

    .card {
        border: 0 !important;
        border-radius: 0 !important;
    }

    .card {
        // height: 11.5rem;

        .add-schedule-row-box {
            padding: 0 !important;
            width: 100%;

            a {
                width: 15rem;
                text-align: center;
                height: 9rem;
                border-radius: 3px;
                margin: 0.7rem 0 0.7rem 1rem;
                position: relative;
                float: left;
            }

            .add-schedule {
                height: 9rem;
                border: 2px dashed #ABABAB;

                .bx-plus {
                    font-size: 50px;
                    padding-top: 28px;
                    color: #979797;
                }

                span {
                    color: #979797;
                    position: absolute;
                    top: 5.5rem;
                    left: 4.3rem;
                }
            }

            .add-schedule:hover {
                border: 2px dashed #8558FA;

                span,
                .bx-plus {
                    color: #8558FA !important;
                }
            }

            .tempList {
                width: 100%;
                overflow: scroll;
                white-space: nowrap;
                height: 182px;
                overflow-y: hidden;
                float: left;
            }

        }
    }

    .search-card-box {
        background-color: #F7F8FA;

        .card-body {
            height: 5rem !important;
        }

        .div-search-box {
            height: 32px !important;
            padding: 0 10px;
            border-radius: 5px;
        }

        .select-box {
            position: relative;

            span {
                position: absolute;
                top: 0.7rem;
            }

            .form-select {
                width: 35%;
                position: absolute;
                top: 6px;
                left: 5rem;
                height: 30px;
                line-height: 13px;
                font-size: 12px;
            }
        }
    }

    .activity-box {
        text-align: right;
        position: relative;

        .search-box {
            width: 50%;
            position: absolute !important;
            right: 6rem;

            input {
                height: 30px !important;
                font-size: 12px;
            }

            i {
                line-height: 33px !important;
            }
        }

        .btnSearch {
            position: absolute;
            right: 20px;
            padding: 5px 20px;
            background: white;
            font-size: 12px;
            top: 0;
        }
    }

    .tabs-card {
        .card-body {
            padding: 0 !important;
        }

        .nav {
            margin-top: 20px;
        }

        .nav-link {
            background: #F7F8FA !important;
            border-top: 1px solid #ccc !important;
            border-left: 1px solid #ccc !important;
            border-right: 1px solid #ccc !important;
            border-bottom: 1px solid #ccc;
            border-radius: 0 !important;
            color: #929AA2 !important;
        }

        .active {
            background: white !important;
            color: #000000cf !important;
        }
    }

    .table-card {
        .card-body {
            padding: 15px 0 !important;
        }
    }

    .dataTableCom {
        width: 100%;
        padding: 0 !important;

        .table> :not(:last-child)> :last-child>* {
            background: #F7F8FA;
        }

        .status-style {
            color: white;
            font-size: 12px;
            padding:5px 10px;
            border-radius: 3px;
        }
    }
}

.ant-pagination {
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right:10px;
}