.messagePushContainer {
    background: white;
    padding: 10px 20px;

    .div-header {
        background-color: #F7F8FA;
        margin: 10px;
        height: 50px;
        line-height: 50px;
        text-align: center;

        .div-first {
            color: #4B5056;
            border-right: 1px solid #ccc;
        }

        .div-second {
            color: #4B5056;
        }

        .div-active {
            background: #8558FA;
            color: white;
        }
    }

    .div-content {
        padding: 20px;

        .col-lg-1,.col-lg-2 {
            text-align: right;
        }

        .push-name {
            width: 400px;
            height: 30px;
            line-height: 12px;
            position: relative;
            top: -5px;
        }

        .div-mobile-bg {
            height: 520px;
            width: 400px;
            background: linear-gradient(45deg,
                    #EEF0F2 25%,
                    transparent 25%,
                    transparent 75%,
                    #EEF0F2 75%,
                    #EEF0F2 100%),
                linear-gradient(45deg,
                    #EEF0F2 25%,
                    transparent 25%,
                    transparent 75%,
                    #EEF0F2 75%,
                    #EEF0F2);
            /* 背景大小必须小于盒子的大小 */
            background-size: 20px 20px;
            /* 第二种渐变的偏移必须为为背景大小的一半 */
            background-position: 0 0, 10px 10px;
        }
        .sms-template-header{
            border: 1px solid #ccc;
            background-color: #F8F8F8;
            font-size: 12px;
            color:#ccc;
            height: 25px;
            line-height: 25px;
            padding: 0 10px;
            border-bottom: 0;
        }
        textarea{
            border-radius: 0;
        }
        .span-style{
            color: #8558FA;
        }
        .div-sms-tips{
            font-size: 12px;
            padding:10px 0;
        }
    }
    .page-footer {
        width: 100%;
        text-align: center;
        margin-top: 50px;

        button {
            margin-right: 30px;
        }
    }
}