.div-add-task {
    float: left;
    position: relative;
    width: 7rem;
    height: 5rem;
    margin: 0 8px;

    .add-task-icon {
        color: #8558FA !important;
        position: absolute;
        top: 2rem;
        left: 1rem;
    }

    button {
        color: #8558FA;
        position: absolute;
        font-size: 12px;
        top: 1.78rem;
        left: 2rem;
        border: 0;
    }

    .right-top {
        border: solid #8558FA;
        display: inline-block;
        border-width: 0px 1.5px 1.5px 0px;
        padding: 4px 90px 2px 5px;
        transform: skewX(43deg);
        margin-left: 5px;
        position: absolute;
        top: 2.7rem;
        left: 0;
    }
}

.span-click {
    color: #8558FA;
    margin: 10px 10px 10px 0;
    display: inline-block;
}
.popover.show.bs-popover-auto {
    top: 10px;
    margin-top: 10px;
}