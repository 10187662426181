
#dat-gui-container {
    position: absolute;
    /*top: 120px;*/
    top:60;
    left: 10;
    z-index: 100001;
    float: right;
    width: 320px;
    margin-right : 60px !important;
}


#xeogl_canvas {
    position: relative;
    top: 0;
    left: 0;
    /*height: calc(1500px - 300px);*/    
    background-color: black;
}





