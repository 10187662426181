.plan-history-content {
    .ant-drawer-content-wrapper {
        width: 87% !important;
    }

    .tasks-row-box {
        background-color: rgb(219, 219, 219);
        border: 1px solid black;
        margin: 20px 0;
        padding: 20px 0;

        .div-tasks {
            text-align: center;

        }
    }
}

.anticon-file-search,.anticon-clear {
    background: #7a7fdc;
    padding: 12px;
    color: white;
    position: relative;
    left: 10px;
    top: -1px;
    border-radius: 5px;
    margin-right:10px;
}
.task-history-modal {
    max-width: 97vw !important;

    .modal-body {
        height: calc(82vh);
    }

    .event-content {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }


    .ant-pagination {
        position: absolute;
        right: 0;
        margin-top: 5px;
        margin-right: 62px;
    }
}
.status-style{
    color:white;
    padding:5px 10px;
    border-radius: 3px;
    font-size: 12px;
}