.btn-go-back {
    padding: 5px 20px;
}

.card {
    margin-bottom: 0px !important;
}

.btn {
    height: 30px;
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 5px 20px !important;
    border-radius: 2px !important;
}

.addCustomScheduleCard {
    margin: 0 20px !important;

    .page-header {
        position: relative;

        i {
            color: #8558FA;
            font-size: 24px;
            padding-left: 10px;
            position: relative;
            top: 3px;
        }

        .col {
            height: 50px;
            line-height: 50px;
        }

        .col-lg-9 {
            text-align: center;

            h3,
            .equity-from,
            .inputPlan {
                display: inline-block;
            }

            .inputPlan {
                display: inline-block;
                width: 230px;
                height: 35px;
                font-size: 18px;
                border: 1px solid #8558FA;
                padding: 0 5px;
            }
        }

        .div-tool-btn {
            position: absolute;
            top: 0;
            right: 20px;

            .btn-save-draft {
                margin-right: 10px;
            }
        }
    }

    .plan-card {
        .card-body {
            padding: 0 !important;
        }

        .col div {
            display: inline-block
        }

        .launch-plan-row {
            border-top: 1px solid #e5e8eb !important;
            margin-top: 1.3rem;
            padding: 10px;
            background-color: #F2F3F5 !important;

            width: 100%;
            overflow: scroll;
            white-space: nowrap;
            height: 115px;
            overflow-y: hidden;

            .plan {
                width: 7.5rem;
                height: 5rem;
                background-color: white;
                border-radius: 3px;
                text-align: center;
                line-height: 5rem;
                float: left;
                position: relative;

                .span-text {
                    position: absolute;
                    top: 1.9rem;
                    line-height: 0px;
                    width: 100%;
                    left: 0;
                    font-size: 12px;
                }

                .span-text2 {
                    position: absolute;
                    top: 3.2rem;
                    line-height: 0px;
                    left: 0;
                    font-size: 16px;
                    width: 100%;
                }

                .bx-x {
                    color: #BDBDBD !important;
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    font-size: 16px;
                }
            }

            .plan-active {
                background-color: #8558FA;
                color: white;
            }

            .plan-default {
                background-color: white;
                color: #495057;
            }

        }
    }

    .dripicons-question {
        color: #BDBDBD;
        position: absolute;
        top: 3px;
        margin: 0 5px;
    }

    .form-card {
        .form-check {
            display: inline-block;
            margin-right: 40px;
        }

        .single-marketing-card {
            background-color: #F2F3F5;
            width: 85%;
            border-radius: 5px !important;

            .card-body {
                padding: 12px 0px !important;
            }

            .col-sm-3 {
                width: 16% !important;
            }

            .trigger-date-label,
            .forever-date-label {
                margin-top: 10px;
            }

            .triggerDateTime,
            .foreverDateTime {
                .ant-picker-input {
                    margin-top: 10px;
                    input{
                        height: 12px;
                    }
                }
            }

            // .trigger-date,
            // .trigger-time {
            //     width: 48%;
            //     display: inline-block;
            //     border-radius: 0;
            //     height: 30px;
            //     margin-right: 15px;
            // }

            // .trigger-time {
            //     width: 35%;
            // }

            .tab-header {
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                width: 60%;
                margin-left: 187px !important;
                margin-top: 12px !important;
                background: #F7F8FA;
                padding-left: 10px;
                // box-shadow: 0px 0px 6px 0px #ccc;
            }

            .div-tab {
                max-height: 200px;
                overflow-y: auto;
                background: white;
                overflow-x: hidden;
                width: 60%;
                margin-left: 187px !important;
                padding-left: 10px;

                .bxs-folder-open {
                    color: #8558FA;
                    width: 22px;
                    font-size: 18px;
                    position: relative;
                    top: 2px;
                }

                .tab-content {
                    border-bottom: 1px solid #EBEDF0;
                    height: 60px;
                    line-height: 60px;
                }

                .tab-content:last-child {
                    border: 0 !important;
                }
            }

            .ant-picker {
                .ant-picker-input {
                    top: -4px !important;
                }
            }
        }

        .col-form-label {
            width: 135px !important;
            text-align: right;
            padding: 0 !important;
        }

        .trigger-date-label,
        .behavior-trigger-label {
            text-align: center;
            width: 100px !important;
        }

        .div-trigger-date {

            position: relative;
            float: left;
            top: -7px;

            .ant-picker-range {
                height: 32px
            }

            .ant-picker-input {
                left: 20px;
            }

            // .start-date,
            // .end-date,
            .span1,
            .span2 {
                position: absolute;
                top: -5px;
                height: 30px;
            }

            // .start-date,
            // .end-date {
            //     width: 10rem;
            // }

            .span1 {
                left: 10.5rem;
                top: 0;
            }

            // .end-date {
            //     left: 12rem;
            // }

            .span2 {
                width: 50px;
                left: 18.5rem;
                top: 6px;
            }
        }

        .customer-behavior-trigger-card {
            background-color: #F2F3F5;
            width: 100%;

            .addCommodity {
                color: #8558FA;
            }

            .div-limit {
                position: relative;
                float: left;

                .span1,
                .span2,
                .form-select,
                #example-number-input,
                .dripicons-question {
                    position: absolute;
                    top: -5px;
                    height: 30px;
                }

                .span1 {
                    width: 70px;
                    top: 0;
                }

                #example-number-input {
                    width: 75px;
                    left: 65px;
                }

                .form-select {
                    width: 5rem;
                    left: 146px;
                    line-height: 12px;
                }

                .span2 {
                    width: 122px;
                    left: 235px;
                    top: 0;
                }

                .dripicons-question {
                    left: 350px;
                    top: 4px;
                }
            }
        }
    }

    .repeat-regularly-card {
        background-color: #F2F3F5 !important;

        .form-select {
            position: absolute;
            height: 30px;
        }

        .form-select {
            width: 75px;
            line-height: 12px;
            font-size: 12px;
        }

        .repeat-time {
            margin-left: 85px;
            width: 8rem;
            height: 30px;
            line-height: 12px;
        }

        .repeat-date-label {
            margin-top: 4px;
            text-align: center;
            width: 100px !important;
        }
    }

    // 发放权益
    .equity-card {
        padding-left: 40px;

        .form-group {
            display: inline-block;
        }

        .taskName {
            height: 30px;
            line-height: 13px;
            font-size: 12px;
            width: 250px;
        }

        .div-task .mb-3 {
            display: inline-block;
        }

        .executionTime-row {
            position: relative;

            .form-control {
                height: 30px;
                line-height: 13px;
                font-size: 12px;
                position: absolute;
                width: 60px;
            }

            .form-select {
                width: 80px;
                position: absolute;
                top: 40px;
                left: 17.2rem;
                height: 30px;
                font-size: 12px;
                line-height: 13px;
            }

            .label-1,
            .label-2,
            .label-3 {
                margin-left: 8px;
            }

            .label-3 {
                margin-left: 10rem;
            }

            .execution-day {
                top: 40px;
                left: 13rem;
            }

            .repeat-time {
                position: absolute;
                top: 40px;
                width: 145px;
                left: 24rem;
            }

            .label-4 {
                margin-left: 10rem;
            }
        }

        .giftTypeRow {
            .mb-3 {
                width: 92%;
            }

            .form-check-input {
                margin-right: 6px;
            }

            .integral-label {
                margin-right: 20px;
            }

            .giftTypeCard {
                background-color: #F2F3F5;
                margin-left: 100px;
                margin-top: 15px;
                width: 100%;

                .input-group {
                    width: 100px;
                    position: absolute;
                    top: -6px;
                    left: 60px;

                    .form-control,
                    .input-group-text {
                        height: 30px;
                        line-height: 13px;
                    }
                }

                .input-group2 {
                    left: 250px;
                }

                .add-coupon {
                    background-color: white;
                    color: #495057;
                    margin-right: 8px;
                    border-color: #e5e8eb;
                    position: relative;
                    top:-2px;
                }
            }

            .div-tips {
                margin: 10px 0 0 100px;
                font-size: 12px;
                color: #e5001d;
            }
        }
        .checkedModal{
            border: 1px solid red;
        }
    }

    //发送消息
    .send-message-card {
        .messageRow {
            position: relative;
            height: 16rem;
        }

        .notification-channel-row {
            position: absolute;
            top: 0;
            left: 7.5rem;

            .col-md-10 {
                padding: 0;
                border: 1px solid #e5e8eb; //#F2F3F5;
                margin-right: 15px;
                height: 6.7rem;

                .card-body {
                    width: 400px;

                    .form-check-input {
                        margin-right: 5px;
                    }

                    .describe-div {
                        font-size: 12px;
                        width: 78%;
                        margin-left: 18px;
                        color: #929292;
                    }

                    .binding-div {
                        height: 70px;
                        text-align: center;
                        background-color: #F2F3F5;
                        width: 250px;
                        margin: 10px 0 0 18px;
                        font-size: 13px;
                        line-height: 20px;
                        padding-top: 15px;
                    }
                }
            }
        }
    }

    .form-check-input {
        width: 0.98em !important;
        height: 0.98em !important;
    }

    //回访任务
    .follow-up-person-card {
        .follow-up-person-div {
            position: fixed;
            margin-left: 100px;

            .label-1 {
                margin-left: 8px;
            }
        }

        .textarea-des {
            position: fixed;
            width: 500px;
            margin-left: 100px;
        }
    }

    //终止计划
    .termination-plan-div {
        margin-left: 130px;
    }
}