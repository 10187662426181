.page-content {
    position: relative;
    min-height: 920px;

    .addDirectionalIssuanceSecurities-container {
        background: white;
        .page-Container {
            padding: 10px 30px;

            .needs-validation {
                padding: 0 20px;
            }

            .row-box {
                margin-top: 20px !important;

                .col-lg-1 {
                    text-align: right !important;
                }

                .form-check {
                    display: inline-block !important;
                    margin-right: 40px !important;
                }

                .col-form-label {
                    width: 125px !important;
                    text-align: right;
                    padding: 0 !important;
                }

                .trigger-date-label,
                .behavior-trigger-label {
                    text-align: center;
                    width: 100px !important;
                }

                .div-trigger-date {
                    position: absolute;
                    left: 90px !important;
                    top: -10px;
                }

                .col-size,
                .div-select-people {
                    font-size: 12px;
                }

                .div-sms-template {
                    background-color: #F8F8F8;
                    margin-left: 128px;
                    font-size: 12px;
                    width: 80%;
                    padding: 20px;
                }

                .table-responsive {
                    margin: 20px 0;
                    width: 50%;
                }

                .div-null {
                    position: relative;
                    height: 60px;
                    line-height: 60px;
                    color: #b4b2b2;
                    width: 100%;
                    left: 31rem;
                }
            }
        }

        .page-footer {
            width: 100%;
            text-align: center;
            height: 90px;
            line-height: 90px;
            margin-top: 30px 0;

            button {
                margin-right: 50px;
            }
        }
    }
}